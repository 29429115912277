import { Entry } from 'contentful'
const cloneDeep = require('rfdc')({ proto: false })

import { pickDataAttrs } from '../../utils/dataAttributes'

/*
 * Generics
 * ---
 * C: Contentful props
 * R: React props
 * K: key of React Props
 */

export type ContentfulToPropsMap<C, R, K extends keyof R> = (prop: Entry<C>) => R[K]

export type MapObject<C, R> = Partial<{
  [Prop in keyof R]: ContentfulToPropsMap<C, R, Prop> | keyof C
}>

export function mapProps<C, R>(props: Entry<C>, contentfulToPropsMap: MapObject<C, R>): C {
  const mappedProps = props.fields
  const dataAttrs = pickDataAttrs(props as unknown as Record<string, unknown>)
  if (!contentfulToPropsMap) {
    return { ...mappedProps, ...dataAttrs }
  }

  Object.entries(contentfulToPropsMap).forEach(([key, value]) => {
    try {
      if (typeof value === 'function') {
        mappedProps[key] = value(props)
      } else {
        mappedProps[key] = props.fields[value as keyof C]
      }
    } catch (e) {
      console.error(e)
    }
  })

  return { ...mappedProps, ...dataAttrs }
}

export function withContentful<C, R = Record<string, unknown>>(Component, contentfulToPropsMap?: MapObject<C, R>) {
  const RenderedComponent: React.FC<Entry<C>> = (props) => {
    const clonedProps = cloneDeep(props) as typeof props
    const mappedProps = mapProps<C, R>(clonedProps, contentfulToPropsMap)
    delete clonedProps.fields
    delete clonedProps.metadata

    return <Component {...mappedProps} {...clonedProps} sys={props.sys} />
  }

  return RenderedComponent
}
