import type { VariantColor, VariantSize } from '@/siteBuilder/blocks/core/RichText/TypographyVariantTypes'
import type { UserFriendlyColorText, UserFriendlySizeText } from './UserTextTypes'

export const CONTENTFUL_TEXT_SIZE_MAPPER: Record<UserFriendlySizeText, VariantSize> = {
  'Display Heading 1': 'display1',
  'Display Heading 2': 'display2',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  Eyebrow: 'eyebrow',

  // Mapper for text
  Large: 'large',
  Medium: undefined,
  Small: 'small',
  Micro: 'micro',
}

export const CONTENTFUL_TEXT_COLOUR_MAPPER: Record<UserFriendlyColorText, VariantColor> = {
  Primary: undefined,
  Secondary: 'default',
  Tertiary: 'light',
}

export const typedLowerCase = <T extends string>(str: T) => {
  if (typeof str === 'string') return str?.toLowerCase() as Lowercase<T>
}

export const isVariantColor = (colour: string): colour is VariantColor => {
  const variantColorArray: string[] = [
    'default',
    'brand',
    'dark',
    'light',
    'positive',
    'warning',
    'danger',
    'black',
    'white',
    'brand2',
    'brand3',
    'alternative1',
    'alternative2',
    'alternative3',
  ]
  return variantColorArray.includes(colour)
}
