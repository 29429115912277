import { withContentful } from '../../../renderer/contentful/withContentful'

import { isObjectDefined } from '@/siteBuilder/utils/isObjectDefined'
import { typedLowerCase } from '@/siteBuilder/utils/text/mappers'

import { type ImageProps } from './Image'

import type { IImageFields } from 'contentful-types'
import Image from './Image'

const ContentfulImage = withContentful<IImageFields, ImageProps>(Image, {
  src: (props) => props.fields.imageAsset?.fields?.file?.url,
  sizes: (props) => ({
    xs: props.fields.xsSize,
    sm: props.fields.smSize,
    md: props.fields.mdSize,
    lg: props.fields.lgSize,
    xl: props.fields.xlSize,
  }),
  width: (props) => props.fields.imageAsset?.fields?.file?.details?.image?.width,
  height: (props) => props.fields.imageAsset?.fields?.file?.details?.image?.height,
  href: 'imageLink',
  rounded: (props) => typedLowerCase(props.fields.rounded),
  position: (props) => {
    const imagePosition = props.fields.position?.fields
    if (!imagePosition) return undefined

    const top = {
      xs: imagePosition.xsImageTop,
      sm: imagePosition.smImageTop,
      md: imagePosition.mdImageTop,
      lg: imagePosition.lgImageTop,
      xl: imagePosition.xlImageTop,
    }
    const right = {
      xs: imagePosition.xsImageRight,
      sm: imagePosition.smImageRight,
      md: imagePosition.mdImageRight,
      lg: imagePosition.lgImageRight,
      xl: imagePosition.xlImageRight,
    }
    const bottom = {
      xs: imagePosition.xsImageBottom,
      sm: imagePosition.smImageBottom,
      md: imagePosition.mdImageBottom,
      lg: imagePosition.lgImageBottom,
      xl: imagePosition.xlImageBottom,
    }
    const left = {
      xs: imagePosition.xsImageLeft,
      sm: imagePosition.smImageLeft,
      md: imagePosition.mdImageLeft,
      lg: imagePosition.lgImageLeft,
      xl: imagePosition.xlImageLeft,
    }

    if (!isObjectDefined(top) && !isObjectDefined(right) && !isObjectDefined(bottom) && !isObjectDefined(left)) {
      top.xs = imagePosition.xsVerticalImagePosition
      top.sm = imagePosition.smVerticalImagePosition
      top.md = imagePosition.mdVerticalImagePosition
      top.lg = imagePosition.lgVerticalImagePosition
      top.xl = imagePosition.xlVerticalImagePosition
      right.xs = imagePosition.xsHorizontalImagePosition
      right.sm = imagePosition.smHorizontalImagePosition
      right.md = imagePosition.mdHorizontalImagePosition
      right.lg = imagePosition.lgHorizontalImagePosition
      right.xl = imagePosition.xlHorizontalImagePosition
    }

    return {
      imagePosition: {
        xs: typedLowerCase(imagePosition.xsImagePosition),
        sm: typedLowerCase(imagePosition.smImagePosition),
      },
      width: {
        xs: imagePosition.xsImageWidth,
        sm: imagePosition.smImageWidth,
        md: imagePosition.mdImageWidth,
        lg: imagePosition.lgImageWidth,
        xl: imagePosition.xlImageWidth,
      },
      top,
      left,
      bottom,
      right,
    }
  },
})

export default ContentfulImage
